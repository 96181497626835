import React from "react";
import {
  FaCalendarAlt,
  FaGithub,
  FaEnvelopeOpenText,
  // FaFacebookF,
  // FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaTwitter,
  FaLightbulb,
} from "react-icons/fa";
import img from "../assets/images/about/avatarnew.jpeg";
import downloadIcon from "../assets/images/download.png";

const HomeCard = () => {
  const redirectToResume = () => {
    window.location.href = 'https://miteshmetha.com/portfolio';
  }

  const redirectToQuest = () => {
    window.location.href = 'https://miteshmetha.com/me';
  }

  return (
    <div>
      <div className="w-full mb-6 lg:mb-0  mx-auto   relative bg-white text-center dark:bg-[#111111] px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 ">
        <img
          src={img}
          className="w-[240px] absolute left-[50%] transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto  rounded-[20px] -mt-[140px]"
          alt=""
        />
        {/* Social card */}
        <div className="pt-[100px] pb-8">
          <h1 className="mt-6 mb-1 text-5xl font-semibold  dark:text-white">
            Mitesh Metha
          </h1>
          <h3 className="mb-4 text-[#7B7B7B] inline-block dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6]  ">
            Product Manager
          </h3>

          {/* Social Links */}

          <div className="flex justify-center space-x-3">
            {/* facebook link add here */}
            {/* <a
              href="https://www.facebook.com/miteshmetha0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#1773EA]">
                <FaFacebookF />
              </span>
            </a> */}
            {/* instagram link add here */}
            {/* <a
              href="https://www.instagram.com/meet_metha_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#1773EA]">
                <FaInstagram />
              </span>
            </a> */}
            {/* twitter link add here */}
            <a
              href="https://twitter.com/miteshmetha"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#1C9CEA]">
                <FaTwitter />
              </span>
            </a>
            {/* github link add here */}
            <a
              href="https://github.com/Meetmetha"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#e14a84] ">
                <FaGithub />
              </span>
            </a>
            {/* linkedin link add here */}
            <a
              href="https://www.linkedin.com/in/miteshmetha"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#0072b1]">
                <FaLinkedinIn />
              </span>
            </a>
            {/* resumeme link add here */}
            {/* <a
              href="https://drive.google.com/file/d/1FZmpMnch-XmxqVObkh1CAkInno2iHb5I/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#0072b1]">
                <FaLightbulb />
              </span>
            </a> */}
          </div>

          {/* personal information */}
          <div className="p-7 rounded-2xl mt-7  bg-[#F3F6F6] dark:bg-[#1D1D1D]">
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#6AB5B9] shadow-md">
                <FaEnvelopeOpenText />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Email
                </p>
                <p className="dark:text-white w-26 break-all">miteshmehta2018@gmail.com</p>
              </div>
            </div>
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#FD7590] shadow-md">
                <FaMapMarkerAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Location
                </p>
                <p className="dark:text-white">Mumbai, India</p>
              </div>
            </div>
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#C17CEB] shadow-md">
                <FaCalendarAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Birthday
                </p>
                <p className="dark:text-white">2 Feb, 1999</p>
              </div>
            </div>
            <div className="flex  py-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#ff69b4] dark:text-[#FFFF00] shadow-md">
                <FaLightbulb />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Personal pitch
                </p>
                <p className="dark:text-white hover:text-[#FA5252] dark:hover:text-[#FA5252] cursor-pointer" onClick={redirectToQuest}>View</p>
              </div>
            </div>
          </div>
          <button onClick={redirectToResume} className="flex items-center mx-auto bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#DD2476]  to-[#fa5252ef] px-8 py-3 text-lg text-white rounded-[35px] mt-6">
            <img src={downloadIcon} alt="icon" className="mr-2" />
            Download Resume
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
