import React from "react";
import Slider from "react-slick";
import UseData from "../Hooks/UseData";

const SliderCommon = () => {
  const { sliderLanguages } = UseData();

  // responsive slider element

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 3,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="bg-[#F8FBFB] dark:bg-[#0D0D0D] max-w-full h-auto   py-10 rounded-xl">
      <h3 className="text-center dark:text-white text-6xl mb-3 font-semibold ">Languages</h3>
      <Slider {...settings} >

        {/* slider array */}

        {sliderLanguages.map((item, i) => (
          <div key={item.id} className="flex items-stretch text-center">
            <img className=" overflow-hidden brand-img self-center" src={item.img} alt="" />
            <p className="dark:text-white text-xs mb-2 mt-4 font-semibold self-end">{item.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderCommon;
