import { useEffect, useState } from "react";
// for work_images
// import work1 from "../assets/images/work_images/1.jpg";
// import work2 from "../assets/images/work_images/2.jpg";
// import work3 from "../assets/images/work_images/3.jpg";
// import work4 from "../assets/images/work_images/4.jpg";
// import work5 from "../assets/images/work_images/5.jpg";
// import work6 from "../assets/images/work_images/6.jpg";
// import work7 from "../assets/images/work_images/7.jpg";
// import work8 from "../assets/images/work_images/8.jpg";
// import work9 from "../assets/images/work_images/9.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.png";
import workSmall2 from "../assets/images/work_images/small/2.png";
import workSmall3 from "../assets/images/work_images/small/3.png";
import workSmall4 from "../assets/images/work_images/small/4.png";
import workSmall5 from "../assets/images/work_images/small/5.png";
import workSmall6 from "../assets/images/work_images/small/6.png";
import workSmall7 from "../assets/images/work_images/small/7.png";
import workSmall8 from "../assets/images/work_images/small/8.png";
import workSmall9 from "../assets/images/work_images/small/9.png";
import workSmall10 from "../assets/images/work_images/small/10.png";
// blog post images
import blog6 from "../assets/images/blog_images/6.jpg";
import blog4 from "../assets/images/blog_images/4.jpg";
import blog2 from "../assets/images/blog_images/2.jpg";
import blog1 from "../assets/images/blog_images/1.jpg";
import blog3 from "../assets/images/blog_images/3.jpg";
import blog5 from "../assets/images/blog_images/5.jpg";
// blog image small
import techIcon1 from "../assets/images/tech/nestjs.svg";
import techIcon2 from "../assets/images/tech/express.svg";
import techIcon3 from "../assets/images/tech/firebase.svg";
import techIcon4 from "../assets/images/tech/aws.svg";
import techIcon5 from "../assets/images/tech/strapi.svg";
import techIcon6 from "../assets/images/tech/elasticsearch.svg";
import techIcon7 from "../assets/images/tech/matrix.svg";
import techIcon8 from "../assets/images/tech/unrealengine.svg";
import techIcon9 from "../assets/images/tech/arduino.svg";
import techIcon10 from "../assets/images/tech/react.svg";
import techIcon11 from "../assets/images/tech/wordpress.svg";
import techIcon12 from "../assets/images/tech/typescript.svg";
import techIcon13 from "../assets/images/tech/javascript.svg";
import techIcon14 from "../assets/images/tech/python.svg";
import techIcon15 from "../assets/images/tech/bash.svg";
import techIcon16 from "../assets/images/tech/nodejs.svg";
import techIcon17 from "../assets/images/tech/solidity.svg";

// import img1 from "../assets/images/slider/avatar-1.svg";
// import img2 from "../assets/images/slider/avatar-2.svg";
// import img3 from "../assets/images/slider/avatar-3.svg";
// import img4 from "../assets/images/slider/avatar-4.svg";
// import img5 from "../assets/images/slider/avatar-5.svg";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
// import icon2 from "../assets/images/icons/icon-3.svg";
// import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";

// web3 exp icons
import web3Icon1 from "../assets/images/web3exp/coinbase.svg";
import web3Icon2 from "../assets/images/web3exp/flipkart.svg";
import web3Icon3 from "../assets/images/web3exp/theproducthouse.jpg";
import web3Icon4 from "../assets/images/web3exp/dehiddenapi.png";
import web3Icon5 from "../assets/images/web3exp/quest.svg";
import web3Icon6 from "../assets/images/web3exp/web3conf.webp";

import web3FIcon1 from "../assets/images/web3features/web3rescue.png";
import web3FIcon2 from "../assets/images/web3features/CryptoWave.png";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaConnectdevelop, FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { SiWeb3Dotjs } from "react-icons/si";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Work Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Rest API",
      title: "NestJS Backend API",
      img: workSmall1,
      imgSmall: workSmall1,
      bg: "#FFF0F0",
      client: "-",
      langages: "NestJS, Typescript",
      link: "https://nestapi.miteshmetha.com",
      linkText: "nestapi.miteshmetha.com",
      description:
        "A Rest API made using NestJS for demonstrating my skills with best praticses. It has register/login/profile endpoints secured by JWT Auth and Live Mongodb Database. Feel Free to Create account and delete them once done.",
    },
    {
      id: "2",
      tag: "Rest API",
      title: "ExpressJs Backend API",
      img: workSmall2,
      imgSmall: workSmall2,
      bg: "#FFF3FC",
      client: "-",
      langages: "ExpressJS, Javascript",
      link: "https://expressapi.miteshmetha.com",
      linkText: "expressapi.miteshmetha.com",
      description:
        "A Rest API made using ExpressJS for demonstrating my skills with best praticses. It has register/login/profile endpoints secured by JWT Auth and Live Mongodb Database. Feel Free to Create account and delete them once done.",
    },
    {
      id: "3",
      tag: "Rest API",
      title: "dyte.io videoSDK Server workflow",
      img: workSmall3,
      imgSmall: workSmall3,
      bg: "#FFF0F0",
      client: "-",
      langages: "ExpressJS, Javascript",
      link: "https://github.com/Meetmetha/dyte-backendsample",
      linkText: "github.com/dyte-backendsample",
      description:
        "It shows Implementing Dyte.io Videosdk server API by considering a flow where an admin creates a Meeting and Teacher & Student Join them by Requesting Authtokens.",
    },
    {
      id: "4",
      tag: "Security",
      title: "Secure File Storage",
      img: workSmall4,
      imgSmall: workSmall4,
      bg: "#E9FAFF",
      client: "-",
      langages: "ReactJS, ExpressJS, Javascript",
      link: "https://ipfs.miteshmetha.com",
      linkText: "ipfs.miteshmetha.com",
      description: 
        "IPFS secure filestorage with file encryption Using Inter Planatary file system and PKKDF2 Algorithm for Encryption Frontend based on Reactjs and NestJS backend api for Interaction",
    },
    {
      id: "5",
      tag: "Rest API",
      title: "Firebase Auth to RestAPI",
      img: workSmall5,
      imgSmall: workSmall5,
      bg: "#FFFAE9",
      client: "-",
      langages: "",
      link: "https://fastauth.miteshmetha.com",
      linkText: "fastauth.miteshmetha.com",
      description: 
        "Quickstart your projects Auth system quickly making use of google identity. Just enter webapi key from firebase app and deploy project which has /login /register /sync endpoints, Just Send user details here it will sync it to Google Identity toolkit.",
    },
    {
      id: "6",
      tag: "Rest API",
      title: "Rest API to Mailchimp",
      img: workSmall6,
      imgSmall: workSmall6,
      bg: "#F4F4FF",
      client: "-",
      langages: "ExpressJS, Javascript",
      link: "-",
      linkText: "-",
      description:
        "API to add user to Mailchimp",
    },
    {
      id: "7",
      tag: "Rest API",
      title: "IPFS API",
      img: workSmall7,
      imgSmall: workSmall7,
      bg: "#FFF0F8",
      client: "-",
      langages: "ExpressJS, Javascript",
      link: "https://ipfsapi.miteshmetha.com",
      linkText: "ipfsapi.miteshmetha.com",
      description:
        "Add your data to ipfs with Encryption password. Algorithm AES is used and Userpassword is never stored on server nor the data (NO Database used nothing is stored at server end).",
    },
    {
      id: "8",
      tag: "Creative",
      title: "VR Simulation of Moon",
      img: workSmall8,
      imgSmall: workSmall8,
      bg: "#FFF0F8",
      client: "-",
      langages: "Unreal Engine 4",
      link: "https://www.youtube.com/watch?v=-B75wISbHAk",
      linkText: "youtube.com/VRSimulationMoon",
      description:
        "VR SImulation of Moon build with Unreal Engine at NASA Space Apps Challenge.",
    },
    {
      id: "9",
      tag: "Rest API",
      title: "TikTok Downloader",
      img: workSmall9,
      imgSmall: workSmall9,
      bg: "#FCF4FF",
      client: "-",
      langages: "ExpressJS, Javascript",
      link: "https://tiktokdl.miteshmetha.com",
      linkText: "tiktokdl.miteshmetha.com",
      description:
        "Send POST request with URL in Json and get Download url containing video with audio and without watermark. Build using Python + Flask it uses webscrapping behind the api.",
    },
    {
      id: "10",
      tag: "Security",
      title: "Web Vulnerability Scanner",
      img: workSmall10,
      imgSmall: workSmall10,
      bg: "#FCF4FF",
      client: "-",
      langages: "Bash",
      link: "-",
      linkText: "-",
      description:
        "Tool to Automate checks for Web Vulnerablities. Automate testing for Web vulnerablities and CVE's written in Bash script combining open source projects from CyberSec community",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: techIcon1,
      date: "177 April",
      category: "Inspiration",
      title: "NestJS",
      bg: "#FCF4FF",
      description: 
        "A Rest API made using NestJS for demonstrating my skills with best praticses. It has register/login/profile endpoints secured by JWT Auth and Live Mongodb Database. Feel Free to Create account and delete them once done",
    },
    {
      id: "2",
      img: blog4,
      imgSmall: techIcon2,
      date: "000 April",
      category: "Inspiration",
      title: "ExpressJS",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "3",
      img: blog2,
      imgSmall: techIcon3,
      date: "21 April",
      category: "Web Design",
      title: "Firebase",
      bg: "#FFF0F0",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "4",
      img: blog5,
      imgSmall: techIcon4,
      date: "27 April",
      category: "Inspiration",
      title: "AWS",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "5",
      img: blog3,
      imgSmall: techIcon5,
      date: "27 April",
      category: "Inspiration",
      title: "Strapi CMS",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "6",
      img: blog6,
      imgSmall: techIcon6,
      date: "27 April",
      category: "Inspiration",
      title: "Elasticsearch",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "7",
      img: blog6,
      imgSmall: techIcon7,
      title: "Matrix.org",
      bg: "#EEFBFF",
    },
    {
      id: "8",
      img: blog6,
      imgSmall: techIcon8,
      title: "UE 4",
      bg: "#EEFBFF",
    },
    {
      id: "9",
      img: blog6,
      imgSmall: techIcon9,
      title: "Arduino",
      bg: "#EEFBFF",
    },
    {
      id: "10",
      img: blog6,
      imgSmall: techIcon10,
      title: "ReactJS",
      bg: "#EEFBFF",
    },
    {
      id: "11",
      img: blog6,
      imgSmall: techIcon11,
      title: "Wordpress",
      bg: "#EEFBFF",
    },
    // {
    //   id: "12",
    //   img: blog6,
    //   imgSmall: techIcon12,
    //   title: "Typescript",
    //   bg: "#EEFBFF",
    // },
    // {
    //   id: "13",
    //   img: blog6,
    //   imgSmall: techIcon13,
    //   title: "Javascript",
    //   bg: "#EEFBFF",
    // },
    // {
    //   id: "14",
    //   img: blog6,
    //   imgSmall: techIcon14,
    //   title: "Python",
    //   bg: "#EEFBFF",
    // },
    // {
    //   id: "15",
    //   img: blog6,
    //   imgSmall: techIcon15,
    //   title: "Bash",
    //   bg: "#EEFBFF",
    // },
    {
      id: "16",
      img: blog6,
      imgSmall: techIcon16,
      title: "NodeJS",
      bg: "#EEFBFF",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/home/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/home/blogs",
      icon: <FaConnectdevelop />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Techs",
      link: "/techs",
      icon: <FaConnectdevelop />,
    },
    {
      id: "05",
      name: "Web3",
      link: "/web3",
      // icon: <RiContactsBookLine />,
      icon: <SiWeb3Dotjs />
    },
  ];

  // Slider image and content for Clients
  const sliderLanguages = [
    {
      id: 4,
      img: techIcon17,
      name: "Solidity"
    },
    {
      id: 2,
      img: techIcon13,
      name: "Javascript"
    },
    {
      id: 1,
      img: techIcon14,
      name: "Python"
    },
    {
      id: 3,
      img: techIcon15,
      name: "Bash"
    },
    {
      id: 5,
      img: techIcon12,
      name: "Typescript"
    },
  ];

  // experience items for about page
  const experienceArray = [
    // {
    //   id: "3",
    //   icon: icon2,
    //   title: "Freelancer",
    //   des: "I help small businesses build a strong web presence through design and consulting services.",
    //   color: "#8774FF",
    //   bg: "#FCF4FF",
    // },
    // {
    //   id: "4",
    //   icon: icon3,
    //   title: "Photography",
    //   des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
    //   color: "#FF6080",
    //   bg: "#FFF4F4",
    // },
    {
      id: "1",
      icon: icon4,
      title: "Project Manager",
      des: "Architecturing Tech and managing product timelines while shipping 🚢",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "2",
      icon: icon5,
      title: "Backend Developer",
      des: "Crafting Rest APIs with NestJS and ExpressJS",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
    {
      id: "3",
      icon: icon1,
      title: "Smart Contract Developer",
      des: "Smart Contract Maestro, Solidity coder, EVM enthusiast. Turning dreams into reality.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "4",
      icon: icon,
      title: "Security Analyst",
      des: "Web2 vulnerabilities (OWASP) expert, Web3 auditing, and champion of secure practices",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
  ];

  // experience items for about page
  const web3ExperienceArray = [
    {
      id: 1,
      icon: web3Icon1,
      title: "Coinbase",
      des: "Batch minting 1.2 Million NFT’s with Scalable API and Inbuilt captcha along with Bot detection methodologies",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: 2,
      icon: web3Icon2,
      title: "Flipkart",
      des: "Laying the simplest API driven Authentication architecture to mint NFT’s on demand! NFT's being dynamic in nature",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: 3,
      icon: web3Icon3,
      title: " TheProductHouse",
      des: "Airdropping NFT’s to wallet address based on their Email address whitelisted, Batch",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: 4,
      icon: web3Icon6,
      title: "Web3Conference",
      des: "Dynamic NFT's upgraded based on Triggers on task completion Social campaign and Physical QR's as tasks",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: 5,
      icon: web3Icon5,
      title: "Quests by Dehidden",
      des: "Quest creation NO-CODE tool for clients to Create & Manage themself all rendered dynamically",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: 6,
      icon: web3Icon4,
      title: "Dehidden API",
      des: "Structuring Centalised API for reusable modules across projects for faster execution",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // experience features items for about page
  const web3FeaturesArray = [
    {
      id: 1,
      icon: web3FIcon2,
      title: "CryptoWave",
      des: "Payments via NFC, Crosschain deposits via Chainlink CCIP,Layerzero and spends on Base chain ",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: 2,
      icon: web3FIcon1,
      title: "Web3Rescue",
      des: "Rescue your Ethereum Tokens and NFT's from Compromised accounts against sweeper (Finalist ETHIndia 2022)",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
  ]

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: "01",
      date: "2017-2021",
      title: "BE in Computer Science",
      place: "Saraswati College of Engineering, Kharghar, Maharashtra",
      bg: "#FFF4F4",

      id1: "02",
      date1: "2015-2017",
      title1: "HSC - 73%",
      place1: "S.K. Somaiya Vinaymandir, Vidyavihar, Maharashtra",
      bg1: "#FFF1FB",

      id2: "03",
      date2: "2021",
      title2: "SSC - 86%",
      place2: "Shreeram Vidyalaya, Airoli, Maharashtra",
      bg2: "#FFF4F4",
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "04",
      date: "Apr 2023 - Current",
      title: "Web3 Developer",
      place: "PlayAI.Network",
      bg: "#EEF5FA",
      
      id1: "05",
      date1: "Dec 2021 - Dec 2022",
      title1: "Technical Product Manager",
      place1: "Dehidden",
      bg1: "#EEF5FA",

      id2: "06",
      date2: "May 2021 - Dec 2021",
      title2: "Senior Backend Developer",
      place2: "Wayfareroworld Advent Pvt Ltd.",
      bg2: "#F2F4FF",

      id3: "07",
      date3: "Feb 2020 - May 2021",
      title3: "Software Developer",
      place3: "Aiisma Inc.",
      bg3: "#EEF5FA",
    },
    // {
    //   type: "Awards",
    //   icon: FaAward,
    //   id: "07",
    //   date: "2015-2017",
    //   title: "  Graphic Designer",
    //   place: "Web Graphy, Los Angeles, CA",
    //   bg: "#FCF4FF",

    //   id1: "08",
    //   date1: "2014 - 2015",
    //   title1: "Jr. Web Developer",
    //   place1: "Creative Gigs.",
    //   bg1: "#FCF9F2",

    //   id2: "09",
    //   date2: "2015-2017",
    //   title2: "Best Freelancer",
    //   place2: "Fiver & Upwork Level 2 & Top Rated",
    //   bg2: "#FCF4FF",
    // },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#099FFF",
      name: "Backend Development",
      number: "75",
    },
    {
      id: "02",
      color: "#00FF00",
      name: "Product Security",
      number: "85",
    },
    {
      id: "03",
      color: "#FF6600",
      name: "Project Management & Architecture",
      number: "80",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    web3ExperienceArray,
    web3FeaturesArray,
    sliderLanguages,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
