import React from "react";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
// import CommonContact from "./CommonContact";
import UseData from "../../Hooks/UseData";
import AboutCard from "../About/AboutCard";

const ContactTwo = () => {
  const { local, web3ExperienceArray, web3FeaturesArray } = UseData();

  return (
    <>
      <PageTitle title="Web3"></PageTitle>
      {/* End pagetitle */}

      <div className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
        {/* Contact page title */}
        <h2 className="after-effect after:left-60 after:top-[76px] mb-12 md:mb-[30px] pl-4 md:pl-[60px] pt-12">
          Web3
        </h2>
        <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
            {web3FeaturesArray.map((item) => (
              <AboutCard key={Math.random()} item={item} local={local} />
            ))}
        </div>
        </div>
        <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
          <h3 className="text-[26px] dark:text-white font-medium pb-5">
            Major Projects
          </h3>
          <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
            {/* Experience information  */}

            {web3ExperienceArray.map((item) => (
              <AboutCard key={Math.random()} item={item} local={local} />
            ))}
          </div>
        </div>
        {/* <CommonContact condition={true} /> */}
        <Footer bg={"#F8FBFB"} />
      </div>
    </>
  );
};

export default ContactTwo;
