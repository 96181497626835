import React from "react";
import CommonPortfilo from "./CommonPortfilo";

const PortfiloTwo = () => {
  return (
    <div>
      <CommonPortfilo items={10} condition={false} />
    </div>
  );
};

export default PortfiloTwo;
